<template>
  <g>
    <svg:style>
      .cls-4 {
        fill: url(#inox-gradient);
      }
    </svg:style>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth1 + 42.56"
      :y1="inox1Y + inoxHeight"
      :x2="doorLeftWidth1 + 42.56"
      :y2="inox1Y"
      xlink:href="#handle-gradient"/>
    <g id="L08">
      <rect
        v-if="showInox"
        id="inox"
        class="cls-4"
        :x="doorLeftWidth1 + 37.41"
        :y="inox1Y"
        width="10.31"
        :height="inoxHeight"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.23,
      leaf2Left: 7,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },

    inox1Y() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leaf2Left
    },
    inoxHeight() {
      return this.showBg ?
        this.doorHeight - 2 * this.inoxOffset :
        this.doorHeight - 2 * this.leaf2Left
    },
  },
}
</script>
